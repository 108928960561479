




















import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class ResursBankFrame extends Vue {
  frameContent = null

  mounted() {
    this.$axios.post('/v3/resurs_bank/init_payment', { order_id: this.$route.params.id }).then((response) => {
      this.frameContent = response.data.data.html
    })
  }

  cancel() {
    this.$router.push('/v3/' + this.$route.params.id + '/order2/main')
  }
}
